import React from 'react';
import Patch from './Patch';
import styled from 'styled-components';

import { ReactComponent as ChevronIcon } from "../../icons/ChevronDown.svg";



const Wrapper = styled.section`
  margin-top: 2rem;
  border: 1px solid ${(props) => props.theme.grayLight};
  background: ${(props) => props.theme.branco};
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);

  &:first-of-type {
    margin-top: 6rem;
  }

  .header {
    h4 {
      font-size: 1.2rem;
      margin-bottom: 1.0rem;
      color: ${(props) => props.theme.grayDark};
    }
    h2 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      color: ${(props) => props.theme.text};
      font-weight: 600;
    }
  }
`;

const PatchNote = ({id, title, content, date}) => {
    return (
        <Wrapper id={id} title={title}>
        <div className="header">
          <h4>{date}</h4>
          <h2>Janeiro 2025</h2>
        </div>
        <Patch content={content} />
      </Wrapper>
    );
};

export default PatchNote;