import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";

import PatchNote from "./PatchNote";

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

const SidenavConfig = styled.div`
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  top: 56px;
  width: 320px;
  height: calc(100vh - 56px);
  border-right: 1px solid #d9dee0;
  flex-grow: 0;
  margin: -40px 4rem -40px -40px;
  padding: 0;

  @media (max-width: 1440px) {
    width: 240px;
  }

  h5 {
    font-size: 1.75rem;
    margin: 2.25rem 2rem 1.25rem;
  }

  a {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  a:hover {
    text-decoration: underline;
  }

  a.active {
    background: ${(props) => props.theme.grayLight};
    text-decoration: underline;
  }
`;

const ContentConfig = styled.div`
  flex-grow: 1;
  .title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .switch-box {
      display: flex;
      align-items: center;
      p {
        font-size: 1.25rem;
        margin-right: 0.5rem;
        font-weight: 400;
      }
    }
  }
`;

const StyledH2 = styled.h2`
  font-size: 3.125rem;
  margin-bottom: -2rem;
`;

const PatchNotes = () => {
  const [patches, setPatches] = useState([
    {
      id: 1,
      title: "Patch 31/01/2025",
      content: "Setembro 2024",
      nome: "patch-31012025",
      date: "31/01/2025",
      content: {
        melhorias: [
          {
            title: "Nenhuma descrição adicionada",
            description:
              "",
          },
        ],
        correcoes: [],
        novasFuncionalidades: []
      },
    },
  ]);

  return (
    <Wrapper>
      <SidenavConfig>
        <h5>Patch Notes</h5>
        {patches.map((patch) => (
          <Link
            activeClass="active"
            to={patch.nome}
            spy={true}
            smooth={true}
            offset={-300}
            duration={500}
          >
            {patch.title}
          </Link>
        ))}
      </SidenavConfig>
      <ContentConfig>
        <StyledH2>Notas de atualização</StyledH2>
        {patches.map((patch) => (
          <PatchNote
            key={patch.id}
            id={patch.nome}
            title={patch.title}
            content={patch.content}
            date={patch.date}
          />
        ))}
      </ContentConfig>
    </Wrapper>
  );
};

export default PatchNotes;
