import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { withRouter, useHistory } from "react-router-dom";

import fileDownload from "js-file-download";

import matchSorter from "match-sorter";

import LoadingIcon from "../LoadingIcon";

import { FloatingButtons } from "../FloatingButtons";
import FloatButton from "../FloatButton";
import ProgressRing from "../ProgressRing";

import ApagaSelectedIcon from "../images/apaga-selected-icon.svg";
import SalvaSelecionadosIcon from "../images/carrega-selecionados-icon.svg";
import SalvaTodosIcon from "../images/carrega-todos-icon.svg";
import BancoService from "../../services/BancoService";

import { ReactComponent as WarningIcon } from "../../icons/warningtriangle-icon.svg";
import { ReactComponent as CheckboxFillIcon } from "../../icons/checkboxfill-icon.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete-icon.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download-icon.svg";
import { ReactComponent as AddIcon } from "../../icons/add-icon.svg";

import DSTable from "../@DesignSystem/Table";
import DSFormInput from "../@DesignSystem/FormInput";
import DSInput from "../@DesignSystem/Input";
import Dropdown from "../@DesignSystem/Dropdown";
import Button from "../@DesignSystem/Button";

import { useInput } from "../../hooks/useInput";

import moment from "moment";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
  notificationTopCenter,
} from "../ToastNotification";

// import { useHistory } from "react-router-dom";

import Modal from "../Modal";
import ArquivosService from "../../services/ArquivosService";

const DownloadSpan = styled.div`
  background-color: ${(props) => props.theme.primaryLight};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Section = styled.div`
  border-radius: 10px;
  margin: 0 0;

  font-family: "Segoe UI", "Verdana", sans-serif;
`;

const Table = styled.div`
  margin: 64px 0 32px;
  background-color: ${(p) => p.theme.background};

  .info-arquivos {
    margin-bottom: 16px;

    p {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 8px;
    }
  }

  p {
    margin: 8px 4px 0;
  }

  .filters {
    display: flex;
  }

  button:first-child {
    margin-right: 32px;
  }

  .alignLeft {
    justify-self: flex-end;
    margin-left: auto;
  }
`;

const ButtonApagaTable = styled.button`
  height: 100%;
  width: 100%;
  border-radius: 0;
  background-color: inherit;
  color: ${(props) => props.theme.text};
  border: none;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  padding: 0;
  font-size: 1.1em;

  img {
    height: 20px;
    transform: translateY(2px);
  }

  &:hover {
    color: ${(props) => props.theme.danger};
  }

  &.download:hover {
    color: ${(props) => props.theme.primary};
  }

  &.carregado {
    cursor: default;
    img {
      filter: invert(65%);
    }
  }

  &.carrega:hover {
    img {
      filter: invert(78%) sepia(45%) saturate(667%) hue-rotate(84deg)
        brightness(102%) contrast(101%);
    }
  }
`;

const StyledSpan = styled.span`
  /* color: ${(props) =>
    ({
      0: props.theme.success,
      1: props.theme.success,
      2: props.theme.success,
      3: props.theme.success,
      4: props.theme.danger,
      5: props.theme.danger,
      6: props.theme.danger,
    }[props.status])}; */
  color: ${({ color, theme }) => theme[color]};
  margin-right: 5px;
  font-size: 1em;

  svg.icon {
    transform: translateY(2px);
  }
`;

const StyledSpanStorage = styled.span`
  color: ${(props) =>
    ({
      0: props.theme.primary,
      1: props.theme.primary,
      2: props.theme.success,
      3: props.theme.success,
      4: props.theme.success,
      5: props.theme.danger,
      6: props.theme.danger,
      7: props.theme.secondary,
    }[props.status])};
  margin-right: 5px;
  font-size: 1em;

  svg.icon {
    transform: translateY(2px);
  }
`;

const UploadButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
    left: -20px;
    pointer-events: none;
  }

  svg.icon {
    position: relative;
    left: 0;
  }
`;

const ModalApagar = styled.div`
  h2 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  p {
    margin: 1rem 0;
  }

  div.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
`;

const Tooltip = styled.span`
  position: relative;
  cursor: pointer;

  &[alt]:after {
    content: attr(alt);
    display: block;
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.background};
    padding: 0;
    opacity: 0;
    border-radius: 4px;
    width: auto;
    word-wrap: normal;
    white-space: nowrap;
    position: absolute;
    top: 0px;
    left: 25%;
    font-size: 12px;
    transition: all 150ms ease-in-out;
  }

  &[alt]:hover:after {
    content: attr(alt);
    opacity: 0.85;
    padding: 4px 8px;
    top: -16px;
    left: 8px;
    font-size: 16px;
  }
`;

// const history = useHistory();

export function TableContainer(props) {
  const [isModalApagaBancoTodosOpen, setIsModalApagaBancoTodosOpen] =
    useState(false);
  const [
    isModalApagaBancoSelecionadosOpen,
    setIsModalApagaBancoSelecionadosOpen,
  ] = useState(false);
  const [isModalApagaStorageTodosOpen, setisModalApagaStorageTodosOpen] =
    useState(false);
  const [
    isModalApagaStorageSelecionadosOpen,
    setisModalApagaStorageSelecionadosOpen,
  ] = useState(false);
  const [isModalAvisoOpen, setisModalAvisoOpen] = useState(false);
  const [atualiza, setatualiza] = useState("");
  const [selection, setselection] = useState([]);
  const [filterTable, setFilterTable] = useState("all");
  const [numberOfFilteredRows, setnumberOfFilteredRows] = useState(0);
  const [globalFilterPagination, setGlobalFilterPagination] = useState("");
  const [filtroBackend, setFiltroBackend] = useState({});
  const [blockUpload, setBlockUpload] = useState(false);

  const { value: globalFilterValue, bind: bindGlobalFilter } = useInput(
    "",
    "",
    "alwaysValid"
  );

  let history = useHistory();

  // useEffect(() => {
  //   console.log(props.data);
  //   console.log(props.banco);
  //   console.log(props.tipoArquivo);
  // }, [props.data, props.banco, props.tipoArquivo]);

  useEffect(() => {
    setFilterTable({ label: "Todos arquivos", value: "all" });
  }, [props.tipoArquivo]);

  const setSelectionCallback = useCallback((selectionData) => {
    setselection(selectionData);
  }, []);

  const apiDownload = (nome_arquivo, tipo_arquivo, token, tamanho_arquivo) => {
    //   let path = process.env.REACT_APP_PORT_KUBERNETS == 443 ? '/backend' :  '';
    //   const options = {
    //     hostname: `${process.env.REACT_APP_URL_KUBERNETS_NOPREFIX}`,
    //     port: process.env.REACT_APP_PORT_KUBERNETS,
    //     path: path + `/api/leitor/arquivos/${nome_arquivo}`,
    //     method: "GET",
    //     headers: {
    //       host: `${process.env.REACT_APP_URL_KUBERNETS_NOPREFIX}:${process.env.REACT_APP_PORT_KUBERNETS}`,
    //       connection: "keep-alive",
    //       "cache-control": "max-age=0",
    //       "upgrade-insecure-requests": "1",
    //       "user-agent":
    //         "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.132 Safari/537.36",
    //       "sec-fetch-dest": "document",
    //       accept: "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
    //       "sec-fetch-site": "cross-site",
    //       "sec-fetch-mode": "navigate",
    //       "sec-fetch-user": "?1",
    //       "accept-encoding": "gzip, deflate, br",
    //       "accept-language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
    //       //Headers taxcel
    //       Authorization: `Bearer ${token}`,
    //       "Tipo-Arquivo": tipo_arquivo,
    //     },
    //   };

    //   return new Promise((resolve, reject) => {
    //     let requisicao_arquivo = https.request(options, () => {});
    //     // console.dir(requisicao_arquivo);
    //     requisicao_arquivo.on("response", (resposta) => {
    //       if (resposta.statusCode >= 200 && resposta.statusCode <= 299) {
    //         resposta.setEncoding("latin1");
    //         resolve(resposta);
    //       } else {
    //         reject("Não foi possivel baixar o arquivo");
    //         // console.dir(resposta);
    //       }
    //     });

    //     requisicao_arquivo.end();
    //   });
    let onDownload = (progressEvent) => {
      let progressoDownload = (
        (progressEvent.loaded / tamanho_arquivo) *
        100
      ).toFixed(0);
      // FAZ ALGUMA COISA COM ISSO?
      // console.log(progressoDownload);
      // this.setState({
      //   [nome_arquivo]: progressoDownload,
      // });
    };

    ArquivosService.downloadArquivoStorage(
      token,
      nome_arquivo,
      tipo_arquivo,
      onDownload
    )
      .then((resp) => {
        fileDownload(resp.data, nome_arquivo);
      })
      .catch(async (err) => {
        // this.setState({
        //   [nome_arquivo]: null,
        // });
        let msg = await err.response.data.text();

        try {
          msg = JSON.parse(msg).descricao;
        } catch (e) {
          msg = "";
        }

        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              {msg ? msg : "Não foi possível baixar o arquivo"}{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const downloadArquivo = (nome_arquivo, tamanho_arquivo) => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode baixar o arquivo agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
    } else {
      store.addNotification({
        content: (
          <ToastNotification bg="primary" textoAuxiliar="">
            Baixando arquivo {nome_arquivo}! Aguarde...{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      apiDownload(
        nome_arquivo,
        props.tipoArquivo,
        props.tokenLogin,
        tamanho_arquivo
      );
    }
  };

  const apagaArquivosStorage = () => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode apagar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
    } else {
      toggleModalApagaStorageTodos();
      store.addNotification({
        content: (
          <ToastNotification bg="danger" textoAuxiliar="">
            Apagando arquivos do storage...
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      ArquivosService.apagaArquivoStorageTipo(
        props.tokenLogin,
        props.tipoArquivo
      )
        .then((resp) => {
          props.updateComponent();
          props.checkOperacao();
          // console.log(resp);
        })
        .catch((err) => {
          console.log(err);
          props.updateComponent();
          // store.addNotification({
          //   content: (
          //     <ToastNotification bg="danger">
          //       Não foi possível apagar os arquivos do Storage ({" "}
          //     </ToastNotification>
          //   ),
          //   onRemoval: (id, removedBy) => //console.log(removedBy),
          //   ...notificationTopRight,
          // });
        });
    }
  };

  const apagaArquivosBanco = () => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode apagar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      toggleModalApagaBancoTodos();
      store.addNotification({
        content: (
          <ToastNotification bg="danger" textoAuxiliar="">
            Apagando arquivos do banco...
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      BancoService.deletaArquivosBancoPorTipo(
        props.tokenLogin,
        props.tipoArquivo
      )
        .then((resp) => {
          //console.log(resp);
          props.updateComponent();
          props.checkOperacao();
        })
        .catch((err) => {
          console.log(err);
          props.updateComponent();
          // store.addNotification({
          //   content: (
          //     <ToastNotification bg="danger">
          //       Não foi possível apagar os arquivos do Banco de dados{" "}
          //     </ToastNotification>
          //   ),
          //   onRemoval: (id, removedBy) => //console.log(removedBy),
          //   ...notificationTopRight,
          // });
        });
    }
  };

  let blockCarrega = false;

  const carregaArquivos = () => {
    if (blockCarrega) return;
    blockCarrega = true;
    setTimeout(() => {
      blockCarrega = false;
    }, 4000);

    console.log("carrega");

    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode carregar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      // console.log(props.data);
      //    let file_ids = props.data.map((arquivo) => arquivo.File_id);
      if (props.data.length === 0) {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary">
              Não há arquivos para serem carregados{" "}
            </ToastNotification>
          ),
          ...notificationTopRight,
        });
        return;
      }
      if (
        props.data.filter((arquivo) => arquivo.upload_status !== 4).length === 0
      ) {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary">
              Todos os arquivos já estão carregados{" "}
            </ToastNotification>
          ),
          ...notificationTopRight,
        });
        return;
      }
      if (props.banco === "storage") {
        BancoService.carregaArquivosBanco(
          props.tokenLogin,
          props.tipoArquivo,
          "todos"
        )
          .then((resp) => {
            //console.log(resp);
            store.addNotification({
              content: (
                <ToastNotification
                  bg="primary"
                  textoAuxiliar="Clique para ver arquivos carregados"
                >
                  Carregando arquivos... Clique aqui para ir à página do Banco
                  de dados e visualizar os arquivos carregados.{" "}
                </ToastNotification>
              ),
              onRemoval: (id, removedBy) => {
                //console.log(removedBy);
                if (removedBy === "click") {
                  //console.log("redirect");
                  props.history.push(`/database/${props.tipoArquivo}`);
                }
              },
              ...notificationTopRight,
            });
            props.updateComponent();
            props.checkOperacao();
            // history.push(`/configuracoes`)
          })
          .catch((err) => {
            console.log(err);
            props.updateComponent();
            // store.addNotification({
            //   content: (
            //     <ToastNotification bg="danger">
            //       Não foi possível carregar os arquivos ao Banco{" "}
            //     </ToastNotification>
            //   ),
            //   onRemoval: (id, removedBy) => //console.log(removedBy),
            //   ...notificationTopRight,
            // });
          });
      }
    }
  };

  const carregaSelecionados = () => {
    if (blockUpload) return;
    setBlockUpload(true);
    setTimeout(() => {
      setBlockUpload(false);
    }, 2000);
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode carregar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
      return;
    }

    if (selection.length === props.data.length) {
      carregaArquivos();
      // console.log("carrega todos banco");
      return;
    }

    let idArquivos = selection.map((arquivo) => arquivo.file_id);

    BancoService.carregaArquivosBanco(
      props.tokenLogin,
      props.tipoArquivo,
      idArquivos
    )
      .then((resp) => {
        //console.log(resp);
        store.addNotification({
          content: (
            <ToastNotification
              bg="primary"
              textoAuxiliar="Clique para ver arquivos carregados"
            >
              Carregando arquivos... Clique aqui para ir à página do Banco de
              dados e visualizar os arquivos carregados.{" "}
            </ToastNotification>
          ),
          onRemoval: (id, removedBy) => {
            //console.log(removedBy);
            if (removedBy === "click") {
              //console.log("redirect");
              props.history.push(`/database/${props.tipoArquivo}`);
            }
          },
          ...notificationTopRight,
        });
        props.updateComponent();
        props.checkOperacao();
      })
      .catch((err) => {
        if (err.response.status === 400) {
          store.addNotification({
            content: (
              <ToastNotification bg="danger">
                Não foi possível carregar os arquivos.{" "}
              </ToastNotification>
            ),
            ...notificationTopRight,
          });
        }
        props.updateComponent();
        // store.addNotification({
        //   content: (
        //     <ToastNotification bg="danger">
        //       Não foi possível carregar os arquivos selecionados ao Banco{" "}
        //     </ToastNotification>
        //   ),
        //   onRemoval: (id, removedBy) => //console.log(removedBy),
        //   ...notificationTopRight,
        // });
      });
  };

  const wrapperCarregaArquivo = (arquivo) => {
    if (blockUpload) return;
    setBlockUpload(true);
    setTimeout(() => {
      setBlockUpload(false);
    }, 2000);
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode carregar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      store.addNotification({
        content: (
          <ToastNotification
            textoAuxiliar="Clique aqui para cancelar"
            bg="success"
          >
            Carregando arquivo para o dashboard...{" "}
          </ToastNotification>
        ),
        onRemoval: (id, removedBy) => {
          //console.log(removedBy);
          if (removedBy === "timeout") {
            BancoService.carregaArquivosBanco(
              props.tokenLogin,
              props.tipoArquivo,
              [arquivo]
            )
              .then((resp) => {
                //console.log(resp);
                // store.addNotification({
                //   content: (
                //     <ToastNotification
                //       bg="primary"
                //       textoAuxiliar="Clique para ver arquivos carregados"
                //     >
                //       Carregando arquivo... Clique aqui para ir à página do Banco de
                //       dados e visualizar os arquivos carregados.{" "}
                //     </ToastNotification>
                //   ),
                //   onRemoval: (id, removedBy) => {
                //     //console.log(removedBy);
                //     if (removedBy === "click") {
                //       //console.log("redirect");
                //       props.history.push(`/database/${props.tipoArquivo}`);
                //     }
                //   },
                //   ...notificationTopRight,
                // });
                props.updateComponent();
                props.checkOperacao();
              })
              .catch((err) => {
                // console.log(err);
                if (err.response.status === 400) {
                  store.addNotification({
                    content: (
                      <ToastNotification bg="danger">
                        Não foi possível carregar o arquivo.{" "}
                      </ToastNotification>
                    ),
                    ...notificationTopRight,
                  });
                }
                props.updateComponent();
                // store.addNotification({
                //   content: (
                //     <ToastNotification bg="danger">
                //       Não foi possível carregar os arquivos selecionados ao Banco{" "}
                //     </ToastNotification>
                //   ),
                //   onRemoval: (id, removedBy) => //console.log(removedBy),
                //   ...notificationTopRight,
                // });
              });
          }
        },
        ...notificationTopRight,
      });
    }
  };

  const apagaSelecionadosStorage = () => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode apagar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
      return;
    }

    let lista_arquivos_nomes = selection.map((arquivo) => arquivo.nome_arquivo);

    props.checkOperacao(200);
    toggleModalApagaStorageSelecionados();
    store.addNotification({
      content: (
        <ToastNotification bg="danger" textoAuxiliar="">
          Apagando arquivos selecionados do storage...
        </ToastNotification>
      ),
      // onRemoval: (id, removedBy) => //console.log(removedBy),
      ...notificationTopRight,
    });
    ArquivosService.apagaArquivoStorageSelecionado(
      props.tokenLogin,
      props.tipoArquivo,
      lista_arquivos_nomes
    )
      .then((resp) => {
        // console.log(resp);
        props.checkOperacao();
        props.updateComponent();
      })
      .catch((err) => {
        console.log(err);
        props.updateComponent();
        store.addNotification({
          content: (
            <ToastNotification bg="danger">
              Não foi possível apagar os arquivos selecionados do Storage{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const apagaSelecionadosBanco = () => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode apagar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
      return;
    }

    toggleModalApagaBancoSelecionados();
    store.addNotification({
      content: (
        <ToastNotification bg="danger" textoAuxiliar="">
          Apagando arquivos selecionados do banco...
        </ToastNotification>
      ),
      // onRemoval: (id, removedBy) => //console.log(removedBy),
      ...notificationTopRight,
    });
    // this.setState({ loadingTable: true });
    let ids = selection.map((arq) =>
      props.isXML ? arq.ID.toString() : arq.File_id.toString()
    );
    BancoService.deletaArquivosBancoPorId(
      props.tokenLogin,
      props.tipoArquivo,
      ids
    )
      .then((resp) => {
        //console.log(resp);
        props.updateComponent();
        // toggleModalApagaBancoSelecionados();
        props.checkOperacao();
      })
      .catch((err) => {
        // console.log(err);
        props.updateComponent();
        // store.addNotification({
        //   content: (
        //     <ToastNotification bg="danger">
        //       Não foi possível apagar os arquivos selecionados do Banco{" "}
        //     </ToastNotification>
        //   ),
        //   onRemoval: (id, removedBy) => //console.log(removedBy),
        //   ...notificationTopRight,
        // });
      });
  };

  const wrapperApagaArquivo = (arquivo) => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode deletar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      store.addNotification({
        content: (
          <ToastNotification
            textoAuxiliar="Clique aqui para cancelar"
            bg="danger"
          >
            Apagando arquivo do Storage...{" "}
          </ToastNotification>
        ),
        onRemoval: (id, removedBy) => {
          //console.log(removedBy);
          if (removedBy === "timeout") {
            ArquivosService.apagaArquivoStorageSelecionado(
              props.tokenLogin,
              props.tipoArquivo,
              { arquivo_unitario: arquivo }
            )
              .then((resp) => {
                props.updateComponent();
                props.checkOperacao();
              })
              .catch((err) => {
                props.updateComponent();
                // store.addNotification({
                //   content: (
                //     <ToastNotification bg="danger">
                //       Não foi possível apagar o arquivo do Storage{" "}
                //     </ToastNotification>
                //   ),
                //   onRemoval: (id, removedBy) => //console.log(removedBy),
                //   ...notificationTopRight,
                // });
              });
          } else {
            store.addNotification({
              content: <ToastNotification>Ação cancelada!</ToastNotification>,
              // onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopCenter,
            });
          }
        },
        ...notificationTopRight,
      });
    }
  };

  const wrapperApagaArquivoBanco = (arquivo) => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode deletar arquivos agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      //console.log(arquivo);
      store.addNotification({
        content: (
          <ToastNotification
            textoAuxiliar="Clique aqui para cancelar"
            bg="danger"
          >
            Apagando arquivo do Banco...{" "}
          </ToastNotification>
        ),
        onRemoval: (id, removedBy) => {
          //console.log(removedBy);
          if (removedBy === "timeout") {
            BancoService.deletaArquivosBancoPorId(
              props.tokenLogin,
              props.tipoArquivo,
              [arquivo]
            )
              .then((resp) => {
                props.updateComponent();
                props.checkOperacao();
              })
              .catch((err) => {
                // console.log(err);
                props.updateComponent();
                // store.addNotification({
                //   content: (
                //     <ToastNotification bg="danger">
                //       Não foi possível apagar o arquivo do Banco{" "}
                //     </ToastNotification>
                //   ),
                //   onRemoval: (id, removedBy) => //console.log(removedBy),
                //   ...notificationTopRight,
                // });
              });
          } else {
            store.addNotification({
              content: <ToastNotification>Ação cancelada </ToastNotification>,
              // onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopCenter,
            });
          }
        },
        ...notificationTopRight,
      });
    }
  };

  const toggleModalApagaBancoTodos = (e) => {
    setIsModalApagaBancoTodosOpen(!isModalApagaBancoTodosOpen);
  };

  const toggleModalAviso = (e) => {
    setisModalAvisoOpen(!isModalAvisoOpen);
  };

  const toggleModalApagaBancoSelecionados = (e) => {
    setIsModalApagaBancoSelecionadosOpen(!isModalApagaBancoSelecionadosOpen);
  };

  const toggleModalApagaStorageTodos = (e) => {
    setisModalApagaStorageTodosOpen(!isModalApagaStorageTodosOpen);
  };

  const toggleModalApagaStorageSelecionados = (e) => {
    setisModalApagaStorageSelecionadosOpen(
      !isModalApagaStorageSelecionadosOpen
    );
  };

  const toggleModalApagaTodos = () => {
    if (props.banco === "storage") {
      toggleModalApagaStorageTodos();
    } else {
      toggleModalApagaBancoTodos();
    }
  };

  const toggleModalApagaSelecionados = () => {
    if (props.banco === "storage") {
      if (selection.length === props.data.length && filterTable === "all") {
        toggleModalApagaStorageTodos();
        // console.log("apaga todos storage");
        return;
      }
      toggleModalApagaStorageSelecionados();
      return;
    }
    if (selection.length === props.data.length) {
      toggleModalApagaBancoTodos();
      // console.log("apaga todos banco");
      return;
    }
    toggleModalApagaBancoSelecionados();
  };

  const handleUploadClick = () => {
    if (
      props.temOperacao &&
      props.operacaoEmAndamento !== "arquivos/post/arquivo"
    ) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode fazer upload de arquivos agora porque já tem uma
            operação em andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      props.handleClick();
    }
  };

  const elementStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <span>
            <StyledSpan db color="primary">
              <CheckboxFillIcon className="icon" />
            </StyledSpan>
            Pronto para carregar
          </span>
        );
      case 1:
        return (
          <span>
            <StyledSpan db color="primary">
              <LoadingIcon fill="primary" />
            </StyledSpan>
            Na fila de carregamento
          </span>
        );
      case 2:
        return (
          <span>
            <StyledSpan db color="primary">
              <LoadingIcon fill="primary" />
            </StyledSpan>
            Carregando
          </span>
        );
      case 3:
        return (
          <span>
            <StyledSpan db color="success">
              <CheckboxFillIcon className="icon" />
            </StyledSpan>
            Carregado
          </span>
        );
      case 4:
        return (
          <span>
            <StyledSpan db color="danger">
              <WarningIcon className="icon" />
            </StyledSpan>
            Falha no carregamento
          </span>
        );
      case 5:
        return (
          <span>
            <StyledSpan db color="danger">
              <LoadingIcon fill="danger" />
            </StyledSpan>
            Na fila para apagar
          </span>
        );
      case 6:
        return (
          <span>
            <StyledSpan db color="danger">
              <LoadingIcon fill="danger" />
            </StyledSpan>
            Apagando
          </span>
        );
      case 7:
        return (
          <span>
            <StyledSpan db color="danger">
              <WarningIcon className="icon" />
            </StyledSpan>
            Corrompido
          </span>
        );
      case 8:
        return (
          <span>
            <StyledSpan db danger>
              <WarningIcon className="icon" />
            </StyledSpan>
            Inválido
          </span>
        );
      case 9:
        return (
          <span>
            <StyledSpan db color="secondary">
              <WarningIcon className="icon" />
            </StyledSpan>
            Duplicado
          </span>
        );
      case 10:
        return (
          <span>
            <StyledSpan db color="secondary">
              <WarningIcon className="icon" />
            </StyledSpan>
            Cheio
          </span>
        );
      default:
        return <span></span>;
    }
  };

  const sortByDate = useMemo((rowA, rowB, id, desc) => {
    if (!rowA || !rowB) return 0;
    var aa = rowA.values[id].split("/").reverse().join(),
      bb = rowB.values[id].split("/").reverse().join();
    return aa < bb ? -1 : aa > bb ? 1 : 0;
  }, []);

  let columnsDatabase = [
    {
      Header: "Nome",
      accessor: "NOME_EMPRESA",
      id: "NOME_EMPRESA",
      filterMethod: (filter, rows) => {
        return matchSorter(rows, filter.value, { keys: ["NOME_EMPRESA"] });
      },
      filterAll: true,
    },
    {
      Header: "CNPJ",
      Footer: "CNPJ",
      accessor: "CNPJ",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["CNPJ"] }),
      filterAll: true,
      align: "right",
      width: 160,
    },
    {
      Header: props.isXML ? "Mês" : "Data Início",
      Footer: props.isXML ? "Mês" : "Data Início",
      accessor: props.isXML ? "DT_EMISSAO" : "DT_INI",
      sortType: (rowA, rowB, id, desc) => {
        if (!rowA || !rowB) return 0;
        var aa = rowA.values[id].split("/").reverse().join(),
          bb = rowB.values[id].split("/").reverse().join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      },
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["DT_INI"] }),
      filterAll: true,
      style: {
        textAlign: "center",
      },
      width: 120,
    },
    props.isXML
      ? {
          Header: "ID",
          Footer: "ID",
          accessor: "ID",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["ID"] }),
          filterAll: true,
          style: {
            textAlign: "center",
          },
          width: 80,
        }
      : {
          Header: "Data Fim",
          Footer: "Data Fim",
          accessor: "DT_FIN",
          sortType: (rowA, rowB, id, desc) => {
            if (!rowA || !rowB) return 0;
            var aa = rowA.values[id].split("/").reverse().join(),
              bb = rowB.values[id].split("/").reverse().join();
            return aa < bb ? -1 : aa > bb ? 1 : 0;
          },
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["DT_FIN"] }),
          filterAll: true,
          style: {
            textAlign: "center",
          },
          width: 120,
        },
    props.isXML
      ? {
          Header: "QTD",
          Footer: "QTD",
          accessor: "QTD",
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ["QTD"] }),
          filterAll: true,
          align: "right",
          width: 80,
        }
      : {
          Header: "PBI Status",
          Footer: "PBI Status",
          accessor: (d) => d.status,
          width: 240,
          id: "pbi_status",
          Cell: (row) => elementStatus(row.value),
          filter: (rows, id, filterValue) => {
            return rows.filter((row) => {
              const rowValue = row.values[id];
              if (filterValue === "all") {
                return true;
              }
              if (filterValue === "salvos") {
                return rowValue !== undefined ? rowValue === 3 : true;
              }
              if (filterValue === "nao-salvos") {
                return rowValue !== undefined ? rowValue === 0 : true;
              }
              if (filterValue === "falha-carregar") {
                return rowValue !== undefined
                  ? rowValue === 4 || rowValue >= 7
                  : true;
              }
              return true;
            });
          },
          Filter: ({ filter, onChange }) => (
            <select
              onChange={(event) => onChange(event.target.value)}
              style={{ width: "100%" }}
              value={filter ? filter.value : "all"}
            >
              <option value="all">Mostrar todos</option>
              <option value="salvos">Carregados</option>
              <option value="nao-salvos">Pronto para carregar</option>
              <option value="erros-salvar">Falha ao carregar</option>
              <option value="fila-salvar">Carregando</option>
              <option value="fila-apagar">Apagando</option>
            </select>
          ),
        },
  ];

  if (!props.isLeitor) {
    let botaoApaga = {
      Cell: ({ row: { original: row } }) => (
        <ButtonApagaTable
          onClick={() => {
            wrapperApagaArquivoBanco(props.isXML ? row.ID : row.File_id);
          }}
        >
          <DeleteIcon className="icon" />
        </ButtonApagaTable>
      ),
      style: {
        textAlign: "center",
        padding: "0px 5px",
      },
      id: "actions",
      filterable: false,
      disableResizing: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
    };
    columnsDatabase = [...columnsDatabase, botaoApaga];
  }

  let columnsStorage = [
    {
      Header: "Nome Arquivo",
      Footer: "Nome Arquivo",
      accessor: "nome_arquivo",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["nome_arquivo"] }),
      filterAll: true,
      maxWidth: 800,
      class: "tooltip",
    },
    {
      Header: "Data Upload",
      Footer: "Data Upload",
      accessor: "upload_date",
      width: 150,
      // sortMethod: (a, b) => {
      //   var aa = a.split("/").reverse().join(),
      //     bb = b.split("/").reverse().join();
      //   return aa < bb ? -1 : aa > bb ? 1 : 0;
      // },
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["upload_date"] }),
      filterAll: true,
      Cell: (row) => <span>{moment(row.value).calendar()}</span>,
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (d) => d.status_bi,
      id: "status_bi",
      width: 160,
      Cell: (row) => elementStatus(row.value),
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          if (filterValue === "all") {
            return true;
          }
          if (filterValue === "salvos") {
            return rowValue !== undefined ? rowValue === 3 : true;
          }
          if (filterValue === "nao-salvos") {
            return rowValue !== undefined ? rowValue === 0 : true;
          }
          if (filterValue === "falha-carregar") {
            return rowValue !== undefined
              ? rowValue === 4 || rowValue >= 7
              : true;
          }
          return true;
        });
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Mostrar todos</option>
          <option value="salvos">Carregados</option>
          <option value="nao-salvos">Pronto para carregar</option>
          <option value="fila-apagar">Apagando</option>
          <option value="falha-carregar">Falha ao carregar</option>
        </select>
      ),
    },
    {
      Header: "Tamanho",
      Footer: "Tamanho",
      accessor: "tamanho",
      width: 120,
      align: "right",
      sortType: "number",
      Cell: ({ value }) => {
        if (value == 0) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(value) / Math.log(k));

        return (value / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
      },
    },
    {
      Cell: ({ row: { original: row } }) => {
        if (row?.status_bi === 3) {
          return (
            <ButtonApagaTable className="carregado" onClick={() => {}}>
              <img src={SalvaTodosIcon} alt="Ícone de carregar arquivo"></img>
            </ButtonApagaTable>
          );
        }
        return (
          <ButtonApagaTable
            className="carrega"
            onClick={() => wrapperCarregaArquivo(row.file_id)}
          >
            <img src={SalvaTodosIcon} alt="Ícone de carregar arquivo"></img>
          </ButtonApagaTable>
        );
      },
      id: "carrega-button",
      style: {
        textAlign: "center",
        padding: "0px 5px",
      },
      filterable: false,
      disableResizing: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Cell: ({ row: { original: row } }) => (
        <ButtonApagaTable onClick={() => wrapperApagaArquivo(row.nome_arquivo)}>
          <DeleteIcon className="icon" />
        </ButtonApagaTable>
      ),
      id: "apaga-button",
      style: {
        textAlign: "center",
        padding: "0px 5px",
      },
      filterable: false,
      disableResizing: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      Cell: ({ row: { original: row } }) => {
        // this.state[row.original.nome_arquivo] &&
        // this.state[row.original.nome_arquivo] !== "100" ? (
        //   <DownloadSpan>
        //     {this.state[row.original.nome_arquivo]}%
        //   </DownloadSpan>
        // ) : (
        return (
          <ButtonApagaTable
            className="download"
            onClick={() => downloadArquivo(row.nome_arquivo, row.tamanho)}
          >
            <DownloadIcon className="icon" />
          </ButtonApagaTable>
        );
      },
      id: "baixa-button",
      style: {
        textAlign: "center",
        padding: "0px 5px",
      },
      disableResizing: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
    },
  ];

  let columnsStorageLeitor = [
    {
      Header: "Nome Arquivo",
      Footer: "Nome Arquivo",
      accessor: "nome_arquivo",
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["nome_arquivo"] }),
      filterAll: true,
      maxWidth: 800,
      class: "tooltip",
    },
    {
      Header: "Data Upload",
      Footer: "Data Upload",
      accessor: "upload_date",
      width: 150,
      // sortMethod: (a, b) => {
      //   var aa = a.split("/").reverse().join(),
      //     bb = b.split("/").reverse().join();
      //   return aa < bb ? -1 : aa > bb ? 1 : 0;
      // },
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, { keys: ["upload_date"] }),
      filterAll: true,
      Cell: (row) => (
        <span>
          {moment(row.value, "YYYY-MM-DDTHH:mm:ss")
            .subtract(3, "hours")
            .calendar()}
        </span>
      ),
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: (d) => d.status_bi,
      id: "status_bi",
      width: 160,
      Cell: (row) => elementStatus(row.value),
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          if (filterValue === "all") {
            return true;
          }
          if (filterValue === "salvos") {
            return rowValue !== undefined ? rowValue === 3 : true;
          }
          if (filterValue === "nao-salvos") {
            return rowValue !== undefined ? rowValue === 0 : true;
          }
          if (filterValue === "falha-carregar") {
            return rowValue !== undefined
              ? rowValue === 4 || rowValue >= 7
              : true;
          }
          return true;
        });
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Mostrar todos</option>
          <option value="salvos">Carregados</option>
          <option value="nao-salvos">Pronto para carregar</option>
          <option value="fila-apagar">Apagando</option>
          <option value="falha-carregar">Falha ao carregar</option>
        </select>
      ),
    },
    {
      Header: "Tamanho",
      Footer: "Tamanho",
      accessor: "tamanho",
      width: 120,
      align: "right",
      sortType: "number",
      Cell: ({ value }) => {
        if (value == 0) return "0 Bytes";

        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(value) / Math.log(k));

        return (value / Math.pow(k, i)).toFixed(2) + " " + sizes[i];
      },
    },
    {
      Cell: ({ row: { original: row } }) => {
        // this.state[row.original.nome_arquivo] &&
        // this.state[row.original.nome_arquivo] !== "100" ? (
        //   <DownloadSpan>
        //     {this.state[row.original.nome_arquivo]}%
        //   </DownloadSpan>
        // ) : (
        return (
          <ButtonApagaTable
            className="download"
            onClick={() => downloadArquivo(row.nome_arquivo, row.tamanho)}
          >
            <DownloadIcon className="icon" />
          </ButtonApagaTable>
        );
      },
      id: "baixa-button",
      style: {
        textAlign: "center",
        padding: "0px 5px",
      },
      disableResizing: true,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      resizable: false,
    },
  ];

  // let columns = React.useMemo(
  //   () =>
  //     props.banco === "database"
  //       ? columnsDatabase
  //       : props.isLeitor
  //       ? columnsStorageLeitor
  //       : columnsStorage,
  //   [props.banco, props.isLeitor, props.tipoArquivo, props.temOperacao]
  // );

  let columns =
    props.banco === "database"
      ? columnsDatabase
      : props.isLeitor
      ? columnsStorageLeitor
      : columnsStorage;

  let preFilters = React.useMemo(
    () =>
      !(props.banco === "database")
        ? [{ id: "status_bi", value: filterTable.value }]
        : props.isXML
        ? []
        : [{ id: "pbi_status", value: filterTable.value }],
    [filterTable, props.banco, props.isXML]
  );

  return (
    <>
      {!props.isLeitor && (
        <FloatingButtons>
          <UploadButton>
            <FloatButton
              onClick={handleUploadClick}
              big
              background="primary"
              texto="Upload de arquivos"
              id="upload-arquivos-floatbutton"
            >
              <AddIcon className="icon" />
            </FloatButton>
            <ProgressRing radius={52} stroke={8} progress={0} color="#FF6E4B" />
          </UploadButton>
          {selection.length ? (
            <>
              {props.banco === "storage" && (
                <FloatButton
                  id="carregar-arquivos-floatbutton"
                  onClick={carregaSelecionados}
                  background="success"
                  texto="Carregar os arquivos selecionados"
                >
                  <img
                    src={SalvaSelecionadosIcon}
                    alt="Ícone de carregar arquivos selecionados"
                  ></img>
                </FloatButton>
              )}
              <FloatButton
                id="apagar-arquivos-floatbutton"
                onClick={
                  selection.length >= 12000000
                    ? toggleModalAviso
                    : toggleModalApagaSelecionados
                }
                background="danger"
                texto="Apagar os arquivos selecionados"
              >
                <img
                  src={ApagaSelectedIcon}
                  alt="Ícone de apaga selecionados"
                ></img>
              </FloatButton>
            </>
          ) : (
            <>
              {props.banco === "storage" && (
                <FloatButton
                  id="carregar-todos-arquivos-floatbutton"
                  onClick={carregaArquivos}
                  white
                  background="success"
                  texto="Carregar todos os arquivos"
                >
                  <img
                    src={SalvaTodosIcon}
                    alt="Ícone de carregar todos arquivos"
                  ></img>
                </FloatButton>
              )}
              <FloatButton
                id="apagar-todos-arquivos-floatbutton"
                onClick={toggleModalApagaTodos}
                white
                background="danger"
                texto="Apagar todos os arquivos"
              >
                <DeleteIcon className="icon" />
              </FloatButton>
            </>
          )}
        </FloatingButtons>
      )}
      <Section>
        {/* <Button background="gray" onClick={toggleAllButton}>
            {selectAllButton
              ? "Limpar seleção"
              : "Selecionar todos arquivos"}
          </Button> */}
        <Table>
          <div className="info-arquivos">
            {selection.length > 0 && (
              <p className="bold">Selecionados {selection.length} arquivos</p>
            )}
          </div>
          <div className="filters">
            {/* <DSFormInput
              required
              width={320}
              name="pesquisaVenda"
              placeholder={`Pesquisar arquivos`}
              {...bindGlobalFilter}
            /> */}
            <DSInput
              short
              label=""
              placeholder="Filtrar arquivos"
              color="primary"
              className
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  let filterStatus = {
                    "nao-salvos": 0,
                    salvos: 3,
                    "falha-carregar": 4,
                  };
                  if (filterStatus[filterTable.value])
                    props.fetchData({
                      filtroNome: globalFilterValue,
                      filtro: filterStatus[filterTable.value],
                    });
                  else {
                    props.fetchData({
                      filtroNome: globalFilterValue,
                    });
                  }
                }
              }}
              {...bindGlobalFilter}
            >
              <Button
                size="small"
                onClick={() => {
                  let filterStatus = {
                    "nao-salvos": 0,
                    salvos: 3,
                    "falha-carregar": 4,
                  };
                  if (filterStatus[filterTable.value])
                    props.fetchData({
                      filtroNome: globalFilterValue,
                      filtro: filterStatus[filterTable.value],
                    });
                  else {
                    props.fetchData({
                      filtroNome: globalFilterValue,
                    });
                  }
                }}
              >
                Filtrar
              </Button>
            </DSInput>
            <Dropdown
              className="alignLeft"
              placeholder="Seleciona o filtro"
              defaultValue={{ label: "Todos arquivos", value: "all" }}
              onChange={(selectedOption) => {
                setFilterTable(selectedOption);
                let filterStatus = {
                  "nao-salvos": 0,
                  salvos: 3,
                  "falha-carregar": 4,
                };
                setFiltroBackend({
                  filtro: filterStatus[selectedOption.value],
                });
                console.log(filterStatus[selectedOption.value]);
                history.push(`?page=0&size=${10}`);
                if (globalFilterValue) {
                  props.fetchData({
                    filtroNome: globalFilterValue,
                    filtro: filterStatus[selectedOption.value],
                  });
                } else {
                  props.fetchData({
                    filtro: filterStatus[selectedOption.value],
                  });
                }
              }}
              value={filterTable}
              options={[
                { label: "Todos arquivos", value: "all" },
                { label: "Arquivos carregados", value: "salvos" },
                { label: "Arquivos não carregados", value: "nao-salvos" },
                { label: "Falha ao carregar", value: "falha-carregar" },
              ]}
            />
          </div>
          {!(
            (filterTable.value === "all" || filterTable.value === "") &&
            globalFilterPagination === ""
          ) && <p>Resultado: {numberOfFilteredRows} linhas</p>}
        </Table>
        <DSTable
          selectRows
          setSelectedRows={setSelectionCallback}
          propsColumns={columns}
          data={props.data}
          globalFilterValue={globalFilterPagination}
          filterValue={preFilters}
          setnumberOfFilteredRows={(value) => setnumberOfFilteredRows(value)}
          csvButton={false}
          csvFilename={`${props.tipoArquivo}-${props.banco}`}
          noDataText={`Nenhum arquivo ${
            props.banco === "database" ? "carregado" : "armazenado"
          }`}
          loading={props.loadingData}
          serverPagination={true}
          totalRowsCount={props.totalArquivos}
          pageCount={props.pageCount}
          fetchData={props.fetchData}
          propDataError={props.propDataError}
          filtro={filtroBackend}
        />
        {/* <MySelectTable
              data={props.data !== null ? props.data : []}
              columns={
                props.banco === "database"
                  ? columnsDatabase
                  : columnsStorage
              }
              keyField={props.banco === "database" ? "File_id" : "File_id"}
              className="-striped"
              filterable={true}
              previousText="Anterior"
              nextText="Próxima"
              loading={this.props.loading || this.state.loadingTable}
              loadingText="Carregando..."
              noDataText={props.loading ? "" : "Nenhum arquivo encontrado"}
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              onFilteredChange={this.mandaNumArquivosFiltrados}
              pageSizeOptions={[10, 25, 50, 100, 1000]}
              defaultPageSize={25}
              showPaginationTop={true}
              getTfootProps={() => ({
                style: { textAlign: "center", display: "none" },
              })}
              ref={(r) => (this.checkboxTable = r)}
              toggleSelection={this.toggleSelection}
              selectAll={selectAll}
              selectType="checkbox"
              toggleAll={this.toggleAll}
              isSelected={this.isSelected}
              getTrProps={this.rowFn}
              defaultSorted={
                props.banco === "storage"
                  ? [
                      {
                        id: "status_bi",
                        desc: true,
                      },
                    ]
                  : [
                      {
                        id: "pbi_status",
                        desc: true,
                      },
                    ]
              }
            /> */}
      </Section>
      {isModalAvisoOpen && (
        <Modal
          id="modal"
          isOpen={isModalAvisoOpen}
          onClose={toggleModalAviso}
          modalSize="md"
          modalTipo="danger"
          pgBackground="danger"
        >
          <ModalApagar>
            <h2>
              Não é possível selecionar mais de 12.000.000 arquivos para deleção
            </h2>
            <p>
              Tente selecionar menos arquivos ou use a opção de apagar todos os
              arquivos
            </p>
            <div className="buttons">
              <Button bg="gray" onClick={toggleModalAviso}>
                Voltar
              </Button>
            </div>
          </ModalApagar>
        </Modal>
      )}
      {isModalApagaStorageTodosOpen && (
        <Modal
          id="modal"
          isOpen={isModalApagaStorageTodosOpen}
          onClose={toggleModalApagaTodos}
          modalSize="md"
          modalTipo="danger"
          pgBackground="danger"
        >
          <ModalApagar>
            <h2>
              Tem certeza de que deseja apagar todos os arquivos do Storage?
            </h2>
            <p>
              Apagar arquivos somente do Storage não removerá eles do dashboard
            </p>
            <div className="buttons">
              <Button bg="gray" onClick={toggleModalApagaTodos}>
                Cancelar
              </Button>
              <Button bg="danger" onClick={apagaArquivosStorage}>
                Apagar
              </Button>
            </div>
          </ModalApagar>
        </Modal>
      )}
      {isModalApagaBancoTodosOpen && (
        <Modal
          id="modal"
          isOpen={isModalApagaBancoTodosOpen}
          onClose={toggleModalApagaTodos}
          modalSize="md"
          modalTipo="danger"
          pgBackground="danger"
        >
          <ModalApagar>
            <h2>
              Tem certeza de que deseja apagar todos os arquivos do Banco?
            </h2>
            <p>Apagar arquivos do Banco removerá eles do dashboard</p>
            <div className="buttons">
              <Button bg="gray" onClick={toggleModalApagaTodos}>
                Cancelar
              </Button>
              <Button bg="danger" onClick={apagaArquivosBanco}>
                Apagar
              </Button>
            </div>
          </ModalApagar>
        </Modal>
      )}
      {isModalApagaStorageSelecionadosOpen && (
        <Modal
          id="modal"
          isOpen={isModalApagaStorageSelecionadosOpen}
          onClose={toggleModalApagaSelecionados}
          modalSize="md"
          modalTipo="danger"
          pgBackground="danger"
        >
          <ModalApagar>
            <h2>
              Tem certeza de que deseja apagar os {selection.length} arquivos
              selecionados do Storage?
            </h2>
            <p>
              Apagar arquivos somente do Storage não removerá eles do dashboard
            </p>
            <div className="buttons">
              <Button bg="gray" onClick={toggleModalApagaSelecionados}>
                Cancelar
              </Button>
              <Button bg="danger" onClick={apagaSelecionadosStorage}>
                Apagar
              </Button>
            </div>
          </ModalApagar>
        </Modal>
      )}
      {isModalApagaBancoSelecionadosOpen && (
        <Modal
          id="modal"
          isOpen={isModalApagaBancoSelecionadosOpen}
          onClose={toggleModalApagaSelecionados}
          modalSize="md"
          modalTipo="danger"
          pgBackground="danger"
        >
          <ModalApagar>
            <h2>
              Tem certeza de que deseja apagar os {selection.length} arquivos
              selecionados do Banco?
            </h2>
            <p>Apagar arquivos do Banco removerá eles do dashboard</p>
            <div className="buttons">
              <Button bg="gray" onClick={toggleModalApagaSelecionados}>
                Cancelar
              </Button>
              <Button bg="danger" onClick={apagaSelecionadosBanco}>
                Apagar
              </Button>
            </div>
          </ModalApagar>
        </Modal>
      )}
    </>
  );
}

export default withRouter(TableContainer);
