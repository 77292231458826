import React, { useState } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components/macro";

import { Link, useLocation, useHistory } from "react-router-dom";

import { useInput } from "./hooks/useInput";

import { GlobalStyle, themeDefault } from "./theme/GlobalStyle";

import { FormInput } from "./components/FormInput";
import Button from "./components/Button";
import LoadingIcon from "./components/LoadingIcon";

import { DefaultButton } from "./theme/GlobalStyle";

import { ReactComponent as AcceptIcon } from "./icons/accept-icon.svg";
import logoTaxdashs from "./components/images/Logo TaxDashs.png";
import PowerBIPartner from "./components/images/PowerBIPartner.svg";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
} from "./components/ToastNotification";
import axios from "axios";
import CadastroService from "./services/CadastroService";

import DSFormInput from "./components/@DesignSystem/FormInput";
import DSButton from "./components/@DesignSystem/Button";

const start = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 680px;
  margin: 3rem auto;
`;

const Logo = styled.a`
  display: block;
  width: 160px;
  margin: 3rem auto;
  text-align: center;

  img {
    width: 100%;
  }
`;

const CadastroContainer = styled.div`
  border-radius: 1rem;
  width: 420px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.05);
  margin: 2rem auto;
  padding: 2rem;
  box-sizing: border-box;
  opacity: 0;
  animation: ${start} 300ms ease-out 200ms forwards;
`;

const FazerLogin = styled.div`
  margin-top: 4rem;
  text-align: center;
  font-size: 1.25rem;

  p {
    margin: 1.5rem;
  }
`;

const HeaderCadastro = styled.h4`
  font-size: 1.5rem;
  text-align: left;
  color: ${(props) => props.theme.text};
  margin-bottom: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  a:last-child {
    text-align: center;

    img {
      height: 36px;
      margin-top: 1.5rem;
      text-align: center;
    }
  }

  button {
    margin: 2rem 0 1rem;
  }

  button:first-child {
    margin: 0;
    align-self: flex-start;
  }
`;

const Success = styled.div`
  text-align: center;

  svg {
    width: 80px;
    height: 80px;
    color: ${(p) => p.theme.primary};
    margin: 2rem auto;
  }
  h5 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 48px;
    font-size: 16px;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TesteCadastro() {
  let history = useHistory();
  let query = useQuery();

  let emailUrl = query.get("email");

  const resetForm = () => {
    resetEmail();
    resetCnpj();
    resetNome();
    resetTel();
    resetLinkedin();
    resetNomeUsuario();
  };

  const client = axios.create({
    baseURL: process.env.REACT_APP_LICENSE_URL,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const handleCadastro = () => {
    if (
      isInvalidEmail ||
      !email ||
      isInvalidCnpj ||
      !cnpj ||
      isInvalidNome ||
      !nome ||
      isInvalidTel ||
      !tel ||
      isInvalidNomeUsuario ||
      !nomeUsuario
    ) {
      checkEmail();
      checkCnpj();
      checkNome();
      checkTel();
      // checkLinkedin();
      checkNomeUsuario();
      return;
    }
    if (isLoading) return;

    let obj = {
      cnpj: cnpj.replace(/\D/g, ""),
      razao_social: nome,
      nome_contato: nomeUsuario,
      email,
      telefone: tel.replace(/\D/g, ""),
      linkedin,
      endereco: `Av. Brg. Faria Lima, 1755 - Jardim Paulistano,  São Paulo - SP`,
      cep: "01452001",
    };

    setIsLoading(true);

    CadastroService.criarCadastroTeste(obj)
      .then((res) => {
        //success
        setStep(1);
        setIsLoading(false);
        resetForm();

        //integracao RD
        const form = new URLSearchParams();

        form.append("source", "teste_taxdash");
        form.append("email", email);
        form.append("nome", nomeUsuario);
        form.append("empresa", nome);
        form.append("telefone", tel);

        client.post(`/RD_Station.aspx`, form).then((resp) => {
          // console.log(resp);
        });
        //-------------

        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Cadastro realizado com sucesso e enviado para aprovação. A
              resposta será enviada para o email cadastrado.{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.response);

        let msg = "";
        if (err.response.data.descricao === "CPF/CNPJ está inválido")
          msg = "CPF/CNPJ inválido. Insira um número válido.";
        else if (err.response.status === 409)
          msg =
            "Não foi possível realizar o cadastro. O CPNJ/CPF já está cadastrado.";
        else
          msg =
            "Não foi possível realizar o cadastro no momento. Tente novamente mais tarde";

        // console.log(msg);
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              {msg}{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
    check: checkEmail,
  } = useInput(emailUrl, "email");
  const {
    value: cnpj,
    isInvalid: isInvalidCnpj,
    bind: bindCnpj,
    reset: resetCnpj,
    check: checkCnpj,
  } = useInput("", "cnpj");
  const {
    value: nome,
    isInvalid: isInvalidNome,
    bind: bindNome,
    reset: resetNome,
    check: checkNome,
  } = useInput("");
  const {
    value: tel,
    isInvalid: isInvalidTel,
    bind: bindTel,
    reset: resetTel,
    check: checkTel,
  } = useInput("", "tel");
  const {
    value: linkedin,
    isInvalid: isInvalidLinkedin,
    bind: bindLinkedin,
    reset: resetLinkedin,
    check: checkLinkedin,
  } = useInput("");
  const {
    value: nomeUsuario,
    isInvalid: isInvalidNomeUsuario,
    bind: bindNomeUsuario,
    reset: resetNomeUsuario,
    check: checkNomeUsuario,
  } = useInput("");

  return (
    <>
      <ThemeProvider theme={themeDefault}>
        <div>
          <GlobalStyle />
          <Container>
            <Logo href="https://www.taxceladdins.com.br/">
              <img src={logoTaxdashs} alt="Logo Taxcel"></img>
            </Logo>
            <CadastroContainer>
              <HeaderCadastro>Cadastro de teste TaxDashs</HeaderCadastro>
              <FormGroup>
                {step === 0 ? (
                  <>
                    <DSFormInput
                      mt={24}
                      type="text"
                      id="nomeUsuario"
                      label="Nome"
                      placeholder="Nome"
                      error={isInvalidNomeUsuario}
                      errorMsg="Campo obrigatório"
                      required
                      {...bindNomeUsuario}
                    />
                    <DSFormInput
                      mt={24}
                      type="email"
                      id="email"
                      label="Email"
                      placeholder="nome@email.com"
                      error={isInvalidEmail}
                      errorMsg="Digite um email válido para continuar"
                      required
                      {...bindEmail}
                    />
                    <DSFormInput
                      mt={24}
                      type="text"
                      id="cnpj"
                      label="CNPJ ou CPF"
                      placeholder="Somente números"
                      error={isInvalidCnpj}
                      errorMsg="Digite um CNPJ ou CPF válido para continuar"
                      required
                      {...bindCnpj}
                    />
                    <DSFormInput
                      mt={24}
                      type="text"
                      id="nome"
                      label="Razão Social ou Nome da Empresa"
                      placeholder="Nome da Empresa"
                      error={isInvalidNome}
                      errorMsg="Campo obrigatório"
                      required
                      {...bindNome}
                    />
                    <DSFormInput
                      mt={24}
                      type="text"
                      id="tel"
                      label="Telefone"
                      placeholder="Somente números"
                      error={isInvalidTel}
                      errorMsg="Digite um telefone válido para continuar"
                      required
                      {...bindTel}
                    />
                    <DSFormInput
                      mt={24}
                      type="text"
                      id="linkedin"
                      label="Link do perfil do LinkedIn"
                      placeholder="https://www.linkedin.com/in/..."
                      // error={isInvalidLinkedin}
                      // errorMsg="Campo obrigatório"
                      required
                      {...bindLinkedin}
                    />
                    <DSButton
                      background="primary"
                      fluid
                      onClick={handleCadastro}
                    >
                      {isLoading ? <LoadingIcon fill="white" /> : "Cadastrar"}
                    </DSButton>
                  </>
                ) : (
                  <Success>
                    <AcceptIcon />
                    <h5>Cadastro realizado com sucesso!</h5>
                    <p>
                      O seu pedido de teste está sendo analisado e em breve você
                      receberá um e-mail do nosso time para acessar a
                      plataforma.
                    </p>
                  </Success>
                )}
                {/* <a
                  href="https://powerbi.microsoft.com/en-us/partners/taxcel-solues-fiscais-s-a/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={PowerBIPartner} alt="PowerBI Partner" />
                </a> */}
              </FormGroup>
            </CadastroContainer>
            <FazerLogin>
              <p>Já possui uma conta?</p>
              <DSButton
                ghost
                color="primary"
                background="primary"
                center
                onClick={() => history.push("/")}
              >
                Fazer Login
              </DSButton>
            </FazerLogin>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
}
