import api from './config/Axios';
export default class OperacaoService {
    static async getOperacoes(token) {
        return api({
            method: 'get',
            url: `/operacoes/aplicacao`,
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
    }

    static async getHistorico(token) {
        return api({
            method: 'get',
            url: `/operacoes/historico/0/10000`,
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        });
    }

    static async atualizaOperacao(token, id) {
        return api({
            method: 'put',
            url: `/operacoes/${id}/`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }
}