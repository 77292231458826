import moment from "moment";
import React, { useState, forwardRef, useEffect } from "react";
import OperacaoService from "../../services/OperacaoService";
import Table from "../@DesignSystem/Table";
import { ReactComponent as BlockIcon } from "../../icons/block.svg";
import { store } from "react-notifications-component";
import {
  ToastNotification,
  notificationTopRight,
} from "../../components/ToastNotification";

import { ReactComponent as WarningIcon } from "../../icons/warningtriangle-icon.svg";
import { ReactComponent as CheckboxFillIcon } from "../../icons/checkboxfill-icon.svg";

import LoadingIcon from "../LoadingIcon";
import styled from "styled-components";

const StyledSpan = styled.span`
  color: ${({ color, theme }) => theme[color]};
  font-size: 1em;
  font-weight: 600;

  svg {
    margin-right: 8px;
    transform: translateY(2px);
  }
`;

const rowSubComponent = ({ row }) => (
  <div className="content">
    <div>
      <p>
        <span className="label">Email: </span>
        {row.original.email}
      </p>
    </div>
    <div>
      <p>
        <span className="label">Situação: </span>
        {row.original.situacao}
      </p>
    </div>
  </div>
);

const elementStatus = (status) => {
  switch (status) {
    case 2:
      return <StyledSpan color="successDark">Concluída</StyledSpan>;
    case 3:
      return <StyledSpan color="danger">Falhou</StyledSpan>;
    default:
      return (
        <StyledSpan color="text">
          <LoadingIcon fill="primary" />
          Em andamento
        </StyledSpan>
      );
  }
};

const colunas = [
  {
    Header: " ",
    acessor: "id",
    stretch: 1,
    maxWidth: 0,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => {
      console.log(row.value);
      return elementStatus(row.value);
    },
    width: 100,
  },
  {
    Header: "Banco",
    accessor: "banco",
  },
  {
    Header: "Operação",
    accessor: "operacao",
    class: "tooltip",
  },

  {
    Header: "Início",
    accessor: "data_inicio",
    width: 100,
  },
];

const historicos = [
  {
    id: 1,
    usuario: "Banco do Brasil",
    email: "email.empresa@empresa.com.br",
    msg: "Operação realizada com sucesso",
    tipo: "Depósito",
    status: 2,
    createdAt: "2021-05-11T15:00:00.000Z",
  },
  {
    id: 2,
    usuario: "Banco do Brasil",
    email: "email.empresa@empresa.com.br",
    msg: "Operação realizada com sucesso",
    tipo: "Depósito",
    status: 3,
    createdAt: "2021-05-11T15:00:00.000Z",
  },
  {
    id: 3,
    usuario: "Banco do Brasil",
    email: "email.empresa@empresa.com.br",
    msg: "Operação realizada com sucesso",
    tipo: "Depósito",
    status: 1,
    createdAt: "2021-05-11T15:00:00.000Z",
  },
];

const Historico = forwardRef((props, ref) => {
  const [operacoes, setOperacoes] = useState([]);

  useEffect(() => {
    OperacaoService.getOperacoes(props.tokenLogin)
      .then((resp) => {
        console.log(resp);
        let operacoes = resp.data.operacoes.map((op) => {
          let data_inicio = moment(op.createdAt).calendar();
          return {
            banco: op.usuario,
            email: op.email,
            situacao: op.msg,
            operacao: op.tipo,
            status: op.status,
            data_inicio: data_inicio,
          };
        });

        setOperacoes(operacoes);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  return (
    <>
      <Table
        data={operacoes}
        propsColumns={colunas}
        expandRows
        rowSubComponent={rowSubComponent}
      />
    </>
  );
});

export default Historico;
